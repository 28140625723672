import * as React from "react";

interface HealthProviderCardProps {
    name: string,
    address: string,
    telephone: string,
    email: string
    selected: boolean,
    forMobileLayout?: boolean
}

const HealthProviderCard: React.FC<HealthProviderCardProps> = ({
                                                                   forMobileLayout = false,
                                                                   name,
                                                                   address,
                                                                   telephone,
                                                                   email,
                                                                   selected
                                                               }) => {


    return (
        <div className={(selected ? "selected-provider " : "") + ("result card ml-0 shadow-sm mb-3 provider-card ")}>
            <div className="brdr place card-body">
                <h6 className="card-title mx-3  mb-3 text-muted">
                    {name}
                    <i className="fad fa-desktop mx-3 text-warning"></i>
                </h6>
                <h6 className="card-subtitle mx-3">
                    {address}
                </h6>
                <h6 className="card-text mx-3 text-muted mt-2">
                    <i className="far fa-phone-alt text-primary mr-1"></i>
                    {telephone}
                </h6>
                <h6 className="card-text mx-3 text-muted mt-2">
                    <i className="far fa-envelope text-primary mr-1"></i>
                    {email}
                </h6>
            </div>
        </div>

    )
}


export default HealthProviderCard

