import React from "react";
import HealthProviderCard from "../components/provider-card";
import Filters from "../components/filters"
import "../index.css";
import Paginate from "../components/paginate";
import HighMap from "../map-sections/Maps";
import Description from "../mobile/Description";
import {Spin} from "antd";


type FilterOptionsType = {
    types?: string[], districts?: string[], searchString?: string
}

export default class HomePage extends React.Component {

    state: {
        error: any | null,
        loading: boolean,
        providers: any[],
        filterOptions: FilterOptionsType,
        paginationDetails: {
            isFirstPage: boolean,
            isLastPage: boolean,
            currentPageNumber: number,
            totalNumberOfPages: number,
            totalNumberOfResults: number
        },
        selectedProvider: any | null
        isMobile: boolean
    };

    descriptionRef: React.RefObject<any>;

    constructor(props: any) {
        super(props);
        this.state = {
            error: null,
            loading: true,
            providers: [],
            filterOptions: {},
            paginationDetails: {
                isFirstPage: true,
                isLastPage: false,
                currentPageNumber: 0,
                totalNumberOfPages: 0,
                totalNumberOfResults: 0
            },
            selectedProvider: null,
            isMobile: true
        };
        this.descriptionRef = React.createRef();
    }

    componentDidMount() {
        this.fetchProviders();
    }

    fetchProviders() {
        fetch('api/providers?page=' + this.state.paginationDetails.currentPageNumber + this.generateForPagination(this.generateRequestParams(this.state.filterOptions)))
            .then(response => response.json())
            .then((result) => {
                this.setState({
                    providers: result.content,
                    paginationDetails: {
                        isFirstPage: result.first,
                        isLastPage: result.last,
                        currentPageNumber: result.number,
                        totalNumberOfPages: result.totalPages,
                        totalNumberOfResults: result.totalElements
                    }
                })
            })
            .catch((error) => {
                this.setState({error})
                console.error(error)
            })
            .finally(() => {
                    this.setState({loading: false})
                }
            )
    }

    onCurrentPage = (pageNumber: number) => {
        this.setState({
            paginationDetails: {
                ...this.state.paginationDetails,
                currentPageNumber: --pageNumber
            }
        }, this.fetchProviders)
    }

    generateDistrict = (parameters: string[]) => {
        return "&district=" + parameters.join("&district=");
    }

    generateTypes = (parameters: string[]) => {
        return "&type=" + parameters.join("&type=");
    }

    generateSearchString = (parameters: string) => {
        return "&searchString=" + parameters;
    }

    generateForPagination = (parameters: string) => {
        return parameters.replace('?', '&');
    }

    generateRequestParams(filterOptions: FilterOptionsType): string {
        if (Object.keys(filterOptions).length === 0 || Object.values(filterOptions).flat().length === 0) {
            return '';
        }
        let params = '';
        if (filterOptions.districts && filterOptions.types && filterOptions.searchString) {
            params += (filterOptions.types && this.generateTypes(filterOptions.types)) + (filterOptions.districts && this.generateDistrict(filterOptions.districts) + (filterOptions.searchString && this.generateSearchString(filterOptions.searchString)));
        } else if (filterOptions.types && filterOptions.districts) {
            params += (filterOptions.types && this.generateTypes(filterOptions.types)) + (filterOptions.districts && this.generateDistrict(filterOptions.districts));
        } else if (filterOptions.types && filterOptions.searchString) {
            params += (filterOptions.searchString && this.generateSearchString(filterOptions.searchString)) + (filterOptions.types && this.generateTypes(filterOptions.types));
        } else if (filterOptions.districts && filterOptions.searchString) {
            params += (filterOptions.searchString && this.generateSearchString(filterOptions.searchString)) + (filterOptions.districts && this.generateDistrict(filterOptions.districts));
        } else if (filterOptions.types) {
            params += filterOptions.types && this.generateTypes(filterOptions.types);
        } else if (filterOptions.districts) {
            params += filterOptions.districts && this.generateDistrict(filterOptions.districts);
        } else if (filterOptions.searchString) {
            params += filterOptions.searchString && this.generateSearchString(filterOptions.searchString);
        }
        return params.replace('&', '?');
    }


    onFilterChanged = (selectedOptions: string[], key: string) => {
        const filterOptions = {...this.state.filterOptions, [key]: selectedOptions};
        this.setState({filterOptions});
        fetch('api/providers' + this.generateRequestParams(filterOptions)
        )
            .then(response => response.json())
            .then((result) => {
                this.setState({
                    providers: result.content,
                    paginationDetails: {
                        isFirstPage: result.first,
                        isLastPage: result.last,
                        currentPageNumber: result.number,
                        totalNumberOfPages: result.totalPages,
                        totalNumberOfResults: result.totalElements
                    }
                })
            })
            .catch((error) => {
                this.setState({error})
                console.error(error)
            })
    }

    onDistrictClicked = (district: string) => {
        let selectedDistricts = this.state.filterOptions.districts ?? [];
        if (selectedDistricts.includes(district)) {
            selectedDistricts = [];
        } else {
            selectedDistricts.push(district);
        }
        this.onFilterChanged(selectedDistricts, 'districts');
    }

    onProviderClicked = (selectedProvider: any) => {
        this.setState({selectedProvider}, () => {
            if (this.descriptionRef && this.descriptionRef.current) {
                this.descriptionRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
            }
        });
    }

    render() {
        const {loading, providers} = this.state;

        return <Spin spinning={loading}>
            <div className="main-container ">
                <Filters onFilterChanged={this.onFilterChanged}/>
                <Paginate paginationDetails={this.state.paginationDetails} onCurrentPage={this.onCurrentPage}
                          selectedRegion={(this.state.filterOptions.districts && this.state.filterOptions.districts[0]) ?? ""}/>
                <div className={"row mx-auto mt-4"}>
                    <div className="col-6" id="result">
                        {providers.map((provider) => <div key={provider.id} onClick={() => {
                                this.onProviderClicked(provider)
                            }}>
                                <HealthProviderCard name={provider.name} address={provider.address}
                                                    telephone={provider.telephone}
                                                    selected={this.state.selectedProvider && provider.id === this.state.selectedProvider.id}
                                                    email={provider.email}
                                                    forMobileLayout={false}/>
                            </div>
                        )}
                    </div>
                    <div className="col-6">
                        <HighMap onPointClicked={this.onDistrictClicked}/>
                    </div>

                </div>

                <div ref={this.descriptionRef} className={"mb-3"}>
                    {this.state.selectedProvider &&
                    <Description provider={this.state.selectedProvider}/>}
                </div>
            </div>
        </Spin>
    }
}

