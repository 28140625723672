import React, {useState} from 'react';
import {Image} from 'antd';

type Props = {
    imageUrls: string[]
}
const ProviderImages = ({imageUrls}: Props) => {
    const [visible, setVisible] = useState(false);

    return (
        <div className={"row ml-2 mb-4"}>
            {imageUrls.slice(0, 3).map(imageUrl => <Image
                    preview={{visible: false}}
                    width={100}
                    src={imageUrl}
                    onClick={() => setVisible(true)}
                />
            )}
            <div style={{display: 'none'}}>
                <Image.PreviewGroup preview={{visible, onVisibleChange: vis => setVisible(vis)}}>
                    {imageUrls.map(imageUrl => <Image
                        src={imageUrl}/>)}
                </Image.PreviewGroup>
            </div>
        </div>


    );
};

export default ProviderImages;
