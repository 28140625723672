import React from "react";
import {Switch, Route, HashRouter} from "react-router-dom";
import './App.css';
import {useMediaQuery} from "react-responsive";
import HomePage from "./pages/home-page";
import MobileHomePage from "./mobile/MobileHomePage";
import 'semantic-ui-css/semantic.min.css'

function App() {
    const isMobile = useMediaQuery({maxWidth: 767})
    return (
        <div className="App">
            <HashRouter>
                <Switch>
                    <Route path="/" render={() => <div>
                        {isMobile && <MobileHomePage/>}
                        {!isMobile && <HomePage/>}
                    </div>}/>
                </Switch>
            </HashRouter>
        </div>
    );
}

export default App;
