import React, {useEffect, useState} from 'react';
import {Navbar} from "react-bootstrap";
import {Drawer, Input, Spin} from "antd";
import {Button} from "semantic-ui-react";
import MobileProviderItem from "./MobileProviderItem";
import './Mobile.css';
import MobilePagination from "./MobilePagination";
import {ProviderType} from "../types/provider";
import Description from "./Description";

const {Search} = Input;

type FilterOptionsType = { types?: string[], districts?: string[], searchString?: string, page?: number }

const MobileHomePage = () => {

    const [selectedProvider, setSelectedProvider] = useState<ProviderType | null>(null);
    const [paginationDetails, setPaginationDetails] = useState({
        isFirstPage: true,
        isLastPage: false,
        currentPageNumber: 0,
        totalNumberOfPages: 0,
        totalNumberOfResults: 0
    });
    const [types, setTypes] = useState<string[]>([]);
    const [providers, setProviders] = useState<ProviderType[]>([]);
    const [loading, setLoading] = useState(false);
    const [filterOptions, setFilterOptions] = useState<FilterOptionsType>({});


    useEffect(() => {
        setLoading(true);
        fetch('api/providers/types')
            .then(response => response.json())
            .then((result) => {
                setTypes(result);
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                    setLoading(false);
                }
            );
    }, []);

    useEffect(() => {
        function generateRequestParams(filterOptions: FilterOptionsType): string {

            function generateDistrict(parameters: string[]) {
                return "&district=" + parameters.join("&district=");
            }

            function generateTypes(parameters: string[]) {
                return "&type=" + parameters.join("&type=");
            }

            function generateSearchString(parameters: string) {
                return "&searchString=" + parameters;
            }

            if (Object.keys(filterOptions).length === 0 || Object.values(filterOptions).flat().length === 0) {
                return '';
            }
            let params = '';
            if (filterOptions.districts && filterOptions.types && filterOptions.searchString) {
                params += (filterOptions.types && generateTypes(filterOptions.types)) + (filterOptions.districts && generateDistrict(filterOptions.districts) + (filterOptions.searchString && generateSearchString(filterOptions.searchString)));
            } else if (filterOptions.types && filterOptions.districts) {
                params += (filterOptions.types && generateTypes(filterOptions.types)) + (filterOptions.districts && generateDistrict(filterOptions.districts));
            } else if (filterOptions.types && filterOptions.searchString) {
                params += (filterOptions.searchString && generateSearchString(filterOptions.searchString)) + (filterOptions.types && generateTypes(filterOptions.types));
            } else if (filterOptions.districts && filterOptions.searchString) {
                params += (filterOptions.searchString && generateSearchString(filterOptions.searchString)) + (filterOptions.districts && generateDistrict(filterOptions.districts));
            } else if (filterOptions.types) {
                params += filterOptions.types && generateTypes(filterOptions.types);
            } else if (filterOptions.districts) {
                params += filterOptions.districts && generateDistrict(filterOptions.districts);
            } else if (filterOptions.searchString) {
                params += filterOptions.searchString && generateSearchString(filterOptions.searchString);
            }

            if (filterOptions.page) {
                params += '&page=' + filterOptions.page;
            }
            return params.replace('&', '?');
        }

        fetch('api/providers' + generateRequestParams(filterOptions)
        )
            .then(response => response.json())
            .then((result) => {
                setProviders(result.content);
                setPaginationDetails({
                    isFirstPage: result.first,
                    isLastPage: result.last,
                    currentPageNumber: result.number,
                    totalNumberOfPages: result.totalPages,
                    totalNumberOfResults: result.totalElements
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }, [filterOptions]);

    function onTypesSelectionChanged(type: string) {
        let types = filterOptions.types ?? [];
        if (types.includes(type)) {
            const indexOfValue = types.indexOf(type);
            if (indexOfValue >= 0) {
                types.splice(indexOfValue, 1);
            }
        } else {
            types.push(type)
        }
        setFilterOptions({...filterOptions, types});
    }

    function onCurrentPage(pageNumber: number) {
        setFilterOptions({...filterOptions, page: --pageNumber});
    }

    function onSearchValueChanged(value: any) {
        setFilterOptions({...filterOptions, searchString: value.target.value});
    }

    function closeBottomSheet() {
        setSelectedProvider(null);
    }


    return (
        <Spin spinning={loading} className={"mt-5"}>
            <div style={{display: 'flex'}}>
                <Navbar fixed="top" className="mobile-navbar-inner " bg="light" variant="light">
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Search
                            allowClear={true}
                            placeholder="Search for..."
                            enterButton="Search"
                            onChange={onSearchValueChanged}
                        />
                        <MobilePagination paginationDetails={paginationDetails}
                                          onCurrentPage={onCurrentPage}
                                          selectedRegion={""}/>
                    </div>
                </Navbar>
                <div>
                    <div style={{marginLeft: 12, marginRight: 12, marginTop: 90, marginBottom: 160}}>
                        {providers.map(provider => <MobileProviderItem onClick={setSelectedProvider}
                                                                       key={provider.id} provider={provider}
                                                                       selected={!!selectedProvider && provider.id === selectedProvider?.id}/>)}
                    </div>
                </div>

                <Navbar fixed="bottom" className="navbar-inner justify-content-between mobile-footer" bg="light"
                        variant="light">
                    <div className="footerMobile">
                        <div className={"d-flex"}>
                            {types.map(type => <span key={type} style={{fontSize: 18, margin: 8}}>
                            <Button color={filterOptions.types?.includes(type) ? 'teal' : undefined} basic
                                    onClick={() => onTypesSelectionChanged(type)}
                                    style={{display: 'flex', alignItems: 'center'}}>
                                <span style={{whiteSpace: 'nowrap'}}> {type}</span>

                            </Button>
                        </span>)}
                        </div>

                    </div>
                </Navbar>
            </div>
            <Drawer
                title={selectedProvider?.name}
                placement={'bottom'}
                closable={true}
                onClose={closeBottomSheet}
                visible={!!selectedProvider}
                height={500}
            >
                {selectedProvider && <Description provider={selectedProvider} mobile={true}/>}
            </Drawer>
        </Spin>

    );
};

export default MobileHomePage;
