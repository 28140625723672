import React from 'react';
import 'antd/dist/antd.css';
import './filters.css';
import '../index.css'
import {Select} from 'antd';
import {Input} from 'antd';

const {Search} = Input;

const {Option} = Select;

const onSearch = (value: any) => {
    console.log(value);
}

function onFocus() {
    console.log('focus');
}

export class Filter extends React.Component<{ onFilterChanged: (selectedOptions: string[], key: 'types' | 'districts' | 'searchString') => void }> {

    state: { types: any[], searchString: any };

    constructor(props: any) {
        super(props);
        this.state = {
            types: [],
            searchString: String
        };

    }

    componentDidMount() {
        fetch('api/providers/types')
            .then(response => response.json())
            .then((result) => {
                this.setState({
                    types: result
                })
            })
            .catch((error) => {
                this.setState({error})
                console.error(error)
            })
            .finally(() => {
                    this.setState({loading: false})
                }
            )

    }

    onDistrictsSelectionChanged = (selectedValues: string[]) => {
        this.props.onFilterChanged(selectedValues, 'districts');
    }

    onTypesSelectionChanged = (selectedValues: string[]) => {
        this.props.onFilterChanged(selectedValues, 'types');
    }

    onSearchString = (value: any) => {
        this.props.onFilterChanged(value, 'searchString')
    }

    onSearchValueChanged = (value: any) => {
        console.log('onSearchValueChanged', value.target.value);
        this.props.onFilterChanged(value.target.value, 'searchString')
    }

    render() {

        const {types} = this.state

        return (

            <div className="mt-5 mx-auto">
                <br/>
                <Search className="search rectangle"
                        allowClear={true}
                        placeholder="Search for..."
                        enterButton="Search"
                        onFocus={onFocus}
                        onChange={this.onSearchValueChanged}
                        onSearch={this.onSearchString}
                        style={{width: '49%', marginTop: -4}}
                >
                </Search>
                &ensp;
                <Select className="filter"
                        mode="multiple"
                        placeholder="Search Provider Type 🔍"
                        defaultValue={[]}
                        onChange={this.onTypesSelectionChanged}
                        style={{width: '49%', marginTop: -4, marginLeft: 3}}
                        onSearch={onSearch}
                >
                    {types.map((type) => (<Option key={type} value={type}>{type} </Option>))}
                </Select>
                <br/>
            </div>
        )
    }
}

export default Filter;
