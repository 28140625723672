import React from 'react';
import {ProviderType} from "../types/provider";
import {Descriptions, Divider} from "antd";
import {FacebookFilled, InstagramFilled, LinkedinOutlined, TwitterOutlined, WhatsAppOutlined} from "@ant-design/icons";
import ProviderImages from "../components/provider-images";

type Props = {
    provider: ProviderType,
    mobile?: boolean,
}
const Description = ({provider, mobile = false}: Props) => {
    function getSocialMediaComponent(key: string, link: string) {
        if (!link.startsWith("https")) {
            link = "https://" + link
        }

        function createFacebookComponent() {
            return <a target="_blank" rel="noreferrer" href={link}><FacebookFilled
                style={{fontSize: '32px', marginLeft: 8, color: 'gray'}}/></a>
        }

        function createInstagramComponent() {
            return <a target="_blank" rel="noreferrer" href={link}><InstagramFilled
                style={{fontSize: '32px', marginLeft: 8, color: 'gray'}}/></a>
        }

        function createLinkedinComponent() {
            return <a target="_blank" rel="noreferrer" href={link}><LinkedinOutlined
                style={{fontSize: '32px', marginLeft: 8, color: 'gray'}}/></a>
        }

        function createWhatsappComponent() {
            return <a target="_blank" rel="noreferrer" href={link}><WhatsAppOutlined
                style={{fontSize: '32px', marginLeft: 8, color: 'gray'}}/></a>
        }

        function createTwitterComponent() {
            return <a target="_blank" rel="noreferrer" href={link}><TwitterOutlined
                style={{fontSize: '32px', marginLeft: 8, color: 'gray'}}/></a>
        }

        switch (key) {
            case 'facebook': {
                return createFacebookComponent();
            }
            case 'instagram': {
                return createInstagramComponent();
            }
            case 'linkedin': {
                return createLinkedinComponent();
            }
            case 'whatsapp': {
                return createWhatsappComponent();
            }
            case 'twitter': {
                return createTwitterComponent();
            }
        }
    }

    function getGoogleMapsLink(provider: ProviderType) {
        let plusCode = provider.location_plus_code;
        plusCode = plusCode.replace("+", "%2B");
        return "https://www.google.com/maps/search/?api=1&query=" + plusCode;
    }

    return (
        <div style={mobile ? {marginBottom: 100} : {
            border: 'solid',
            borderWidth: 1,
            borderColor: 'lightgray',
            padding: 16,
            marginTop: 16
        }}>
            {(provider?.photo_urls && provider?.photo_urls.length > 0) &&
            <ProviderImages imageUrls={provider.photo_urls}/>}
            <Descriptions title={provider?.name} size={'middle'}>
                <Descriptions.Item label="Address">
                    {(provider?.location_plus_code && provider?.address) ?
                        <a href={getGoogleMapsLink(provider)} target={'_blank'}
                           rel="noreferrer">{provider?.address} </a> : provider?.address}
                </Descriptions.Item>
                <Descriptions.Item label="Region">{provider?.region}</Descriptions.Item>
                <Descriptions.Item label="Type">{provider?.type ?? 'empty'}</Descriptions.Item>
                <Descriptions.Item
                    label="Contact Email">{<a
                    href={`mailto:${provider?.email}`}>{provider?.email}</a> ?? 'empty'}</Descriptions.Item>
                <Descriptions.Item
                    label="Contact Phone">{<a
                    href={`tel:${provider?.telephone}`}>{provider?.telephone}</a> ?? 'empty'}</Descriptions.Item>

                {provider?.website &&
                <Descriptions.Item label="Website">{<a
                    href={provider?.website}
                    target="_blank" rel="noreferrer">{provider?.website}</a>}</Descriptions.Item>}
                {provider?.services_offered && <Descriptions.Item
                    label="Services Offered">{provider?.services_offered}</Descriptions.Item>}
                {provider?.special_services && <Descriptions.Item
                    label="Special Services">{provider?.special_services}</Descriptions.Item>}
                {provider?.description &&
                <Descriptions.Item label="Description">{provider?.description}</Descriptions.Item>}

            </Descriptions>

            {provider?.social_media_links && Object.values(provider.social_media_links).length > 0 &&
            <Divider type={"horizontal"}/>}
            {provider?.social_media_links && Object.values(provider.social_media_links).length > 0 &&
            <Descriptions.Item>
                {Object.keys(provider.social_media_links)
                    .filter((socialMediaKey) => !!provider.social_media_links[socialMediaKey as 'facebook' | 'instagram' | 'whatsapp' | 'linkedin' | 'twitter'])
                    .map((socialMediaKey: string) => getSocialMediaComponent(socialMediaKey,
                        provider.social_media_links[socialMediaKey as 'facebook' | 'instagram' | 'whatsapp' | 'linkedin' | 'twitter']))}
            </Descriptions.Item>}

            {provider?.open_hours && <Divider dashed type={"horizontal"}/>}

            {provider?.open_hours && <div className={"d-flex flex-column"}>
                        <span style={{
                            color: 'rgba(0, 0, 0, 0.85)',
                            fontWeight: 'bold',
                            fontSize: 16,
                            lineHeight: 1.5715,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}>Open Hours:
                        </span>
                {provider.open_hours.sunday &&
                <span>Sunday: {provider.open_hours.sunday}</span>}
                {provider.open_hours.monday_friday &&
                <span>Monday - Friday: {provider.open_hours.monday_friday}</span>}
                {provider.open_hours.saturday &&
                <span>Saturday: {provider.open_hours.saturday}</span>}
            </div>
            }
        </div>
    );
};

export default Description;
