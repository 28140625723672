import React from 'react';
import {ProviderType} from "../types/provider";

type Props = {
    provider: ProviderType,
    selected: boolean,
    onClick: (provider: any) => void
}

const MobileProviderItem = ({
                          provider,
                          selected,
                          onClick
                      }: Props) => {
    const {
        name,
        address,
        telephone,
        email
    } = provider;

    return (
        <div className={"mobile-result card ml-0 shadow-sm mb-3 " + (selected ? 'mobile-selected-item' : '')}
             onClick={() => onClick(provider)}>
            <div className="brdr place card-body">
                <h6 className="card-title mx-3  mb-3 text-muted">
                    {name}
                    <i className="fad fa-desktop mx-3 text-warning"></i>
                </h6>
                <h6 className="card-subtitle mx-3">
                    {address}
                </h6>
                <h6 className="card-text mx-3 text-muted mt-2">
                    <i className="far fa-phone-alt text-primary mr-1"></i>
                    {telephone}
                </h6>
                <h6 className="card-text mx-3 text-muted mt-2">
                    <i className="far fa-envelope text-primary mr-1"></i>
                    {email}
                </h6>
            </div>
        </div>
    );
};

export default MobileProviderItem;
