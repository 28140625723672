import React from 'react';
import {Pagination} from 'antd';
import 'antd/dist/antd.css';
import "./pagination.css"


type Props = {
    paginationDetails: {
        isFirstPage: boolean,
        isLastPage: boolean,
        currentPageNumber: number,
        totalNumberOfPages: number,
        totalNumberOfResults: number
    },
    onCurrentPage: (pageNumber: number) => void
    selectedRegion: string
}

const Paginate = ({paginationDetails, onCurrentPage, selectedRegion}: Props) => {

    return (
        <div className="row" style={{marginTop: 40, marginLeft: 16}}>
            <div>
                <p>
                    <b>{paginationDetails.totalNumberOfResults} Results </b>
                    <span style={{
                        color: '#868686',
                        fontFamily: '\'SF Pro\', serif'
                    }}>{selectedRegion ? ('in ' + selectedRegion) : ('')}</span>
                </p>
            </div>
            <div style={{marginLeft: 20, marginTop: -5}}>
                <Pagination
                    defaultCurrent={1}
                    onChange={onCurrentPage}
                    current={paginationDetails.currentPageNumber + 1}
                    total={paginationDetails.totalNumberOfResults}
                    showSizeChanger={false}
                    pageSize={5}
                />
            </div>

        </div>
    );
};

export default Paginate;
