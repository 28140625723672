import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMap from "highcharts/modules/map";
import UgandaMap from './uganda-map.json';
import './map.css';
import MediaQuery from 'react-responsive'

HighchartsMap(Highcharts);

const chart: Highcharts.MapLatLonObject = {
    lat: 1.373333,
    lon: 32.290276
}

export default class HighMap extends React.Component<{ onPointClicked: (district: string) => void }, { districts?: { [name: string]: number }[] }> {

    state = {
        districts: []
    }

    componentDidMount() {
        fetch('/api/providers/districts/density')
            .then(response => response.json())
            .then(districts => {
                districts = districts.map((district: { district: string, count: number }) => {
                    const name = district.district;
                    const value = district.count;
                    return {
                        id: name,
                        name,
                        value,
                        color: value === 0 ? '#e7edf4' : (value >= 1 && value <= 20) ? '#b7c9e1' : '#88a5ce',
                    }
                })

                function setStateFunction(state: any, props: any) {
                    return {...state, districts: districts};
                }

                this.setState(setStateFunction);
            }).catch(e => console.error(e)
        )

    }


    onPointClicked = (e: any) => {
        if (e.point) {
            this.props.onPointClicked(e.point.name);
        }
    }

    render() {
        const options: Highcharts.Options = {
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: 'bottom'
                }
            },

            legend: {
                enabled: false
            },

            credits: {
                enabled: false
            },

            chart: {
                type: 'map',
                map: UgandaMap,
                borderWidth: 0,
                borderColor: '#FFFFFF',
                borderRadius: 0,
                shadow: false,
                height: 550
            },

            series: [
                {
                    data: this.state.districts,
                    name: '',
                    joinBy: 'name',
                    allAreas: true,
                    type: 'map',
                    states: {
                        hover: {
                            color: '#7093c5'
                        },
                        select: {
                            color: '#416fb2'
                        }
                    },
                    allowPointSelect: true,
                    color: '#e7edf4',
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}'
                    },
                    point: {
                        events: {
                            click: this.onPointClicked.bind(this)
                        }
                    }
                }
            ]
        };
        return (
            <div>
                <MediaQuery maxWidth={720}>
                      <div className="map mobile-showing">
                    <HighchartsReact
                        constructorType={"mapChart"}
                        chart={chart}
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>

                </MediaQuery>

                <MediaQuery minWidth={721}>
                <div className="map border desktop-showing">
                        <HighchartsReact
                            constructorType={"mapChart"}
                            chart={chart}
                            highcharts={Highcharts}
                            options={options}
                        />
                </div>
                </MediaQuery>


                </div>
        )
    }
}

