import React from 'react';
import {Pagination} from 'antd';
import 'antd/dist/antd.css';



type Props = {
    paginationDetails: {
        isFirstPage: boolean,
        isLastPage: boolean,
        currentPageNumber: number,
        totalNumberOfPages: number,
        totalNumberOfResults: number
    },
    onCurrentPage: (pageNumber: number) => void
    selectedRegion: string
}

const MobilePagination = ({paginationDetails, onCurrentPage, selectedRegion}: Props) => {

    return (
        <div className="row page " style={{marginLeft: 25, marginTop: 15}}>
            <div className="row">
                <div style={{marginLeft: 5, marginTop: -5}}>
                    <Pagination
                        defaultCurrent={1}
                        onChange={onCurrentPage}
                        current={paginationDetails.currentPageNumber+1}
                        total={paginationDetails.totalNumberOfResults}
                        showSizeChanger={false}
                        pageSize={5}
                    />
                </div>
            </div>

        </div>
    );
};

export default MobilePagination;
